import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SeatlyBookingWidgetComponent } from './seatly-booking-widget/seatly-booking-widget.component';
import { createCustomElement } from '@angular/elements';
import { SelectGuestsComponent } from './pages/select-guests/select-guests.component';
import { TranslateModule } from '@ngx-translate/core';
import {Router, RouterModule} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from './i18n';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectDateComponent } from './pages/select-date/select-date.component';
import { SelectTimeComponent } from './pages/select-time/select-time.component';
import { ContactInfoComponent } from './pages/contact-info/contact-info.component';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './components/stepper/stepper.component';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ShimmerComponent } from './components/shimmer/shimmer.component';
import * as Sentry from "@sentry/angular";
import { PhoneInputComponent } from './components/phone-input/phone-input.component';

@NgModule({
  declarations: [
    AppComponent,
    SeatlyBookingWidgetComponent,
    SelectGuestsComponent,
    SelectDateComponent,
    SelectTimeComponent,
    ContactInfoComponent,
    ConfirmationComponent,
    LoaderComponent,
    ShimmerComponent,
    StepperComponent,
    PhoneInputComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    RouterModule.forRoot([]),
    FormsModule,
    I18nModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
    }),
    BrowserAnimationsModule,
    NgbModule,
  ],
  bootstrap: [AppComponent], //remove on deploy, I guess?
  providers: [{
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {
  /** This creates the web components for the app */
  constructor(private injector: Injector) {
    // Convert `Component` to a custom element.
    const SeatlyBooking = createCustomElement(SeatlyBookingWidgetComponent, { injector: this.injector });
    // Register the custom element with the browser.
    customElements.define('seatly-booking-widget', SeatlyBooking);
  }

  ngDoBootstrap() {}
}
