import { Component } from '@angular/core';

@Component({
  selector: 'app-missing-attributes-error',
  templateUrl: './missing-attributes-error.component.html',
  styleUrls: ['./missing-attributes-error.component.css']
})
export class MissingAttributesErrorComponent {

}
