import { Component, Input, OnInit } from '@angular/core';
import { BookingRequest } from '@app/models/booking-request.model';
import { convertMinutesSinceMidnightToHumanReadableTime } from '@app/utils/time';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent implements OnInit{
  @Input() bookingRequest?: BookingRequest;
  @Input() restaurantName?: string;
  @Input() contactEmail?: string;
  @Input() contactPhone?: string;

  selectedTimeHumanReadable?: string;


  constructor() {}

  ngOnInit(): void {
    if(this.bookingRequest?.timeslot){
      this.selectedTimeHumanReadable = convertMinutesSinceMidnightToHumanReadableTime(this.bookingRequest.timeslot);
    }
  }
}
