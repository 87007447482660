import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-select-guests',
  templateUrl: './select-guests.component.html',
  styleUrls: ['./select-guests.component.css']
})
export class SelectGuestsComponent implements OnInit {
  @Input() maxGuests: number = 7;
  @Input() numberOfGuests: number = 0;
  @Input() isLoading: boolean = false;

  @Input() contactPhone?: string;
  @Input() contactEmail?: string;

  // From API
  @Input() restaurantName?: string;

  numberOfGuestsForm = new FormGroup({
    numberOfGuests: new FormControl(0, [Validators.required, Validators.min(1), Validators.max(this.maxGuests)]),
  });

  @Output() selectGuests: EventEmitter<number> = new EventEmitter<number>();

	constructor() {}

  ngOnInit(): void {
    if(this.numberOfGuests && this.numberOfGuests > 0){
      this.numberOfGuestsForm.get('numberOfGuests')?.setValue(this.numberOfGuests);
    }
  }

  submit(){
    this.numberOfGuestsForm.markAllAsTouched();

    const selectedNumberOfGuests = this.numberOfGuestsForm.get('numberOfGuests')?.value;
    if(selectedNumberOfGuests && selectedNumberOfGuests > 0){
      this.selectGuests.emit(selectedNumberOfGuests);
    }
  }
}
