<div class="select-date-wrapper">

  <div class="summary-wrapper">
    <h2 class="stepper-title" translate>contact_info.contact_info</h2>
    <span [translateParams]="{ guests: numberOfGuests }" translate>stepper.date_summary&nbsp;</span>
    <span translate>• {{selectedDate?.toFormat('d MMMM yyyy')}} </span>
    <span translate>• {{selectedTimeHumanReadable}}</span>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="contactInfoForm" class="contact-form">
    <div class="form-element">
      <label translate>contact_info.firstname</label>
      <input formControlName="firstname" type="text" [placeholder]="'contact_info.firstname' | translate" class="input input-bordered w-full"/>
      <p *ngIf="contactInfoForm.controls['firstname'].invalid && contactInfoForm.controls['firstname'].touched" translate class="required">
        contact_info.required_field
      </p>
    </div>

    <div class="form-element">
      <label translate>contact_info.lastname</label>
      <input formControlName="lastname" type="text" [placeholder]="'contact_info.lastname' | translate" class="input input-bordered w-full"/>
      <p *ngIf="contactInfoForm.controls['lastname'].invalid && contactInfoForm.controls['lastname'].touched" translate class="required">
        contact_info.required_field
      </p>
    </div>

    <div class="form-element">
      <label translate>contact_info.email</label>
      <input formControlName="email" type="email" placeholder="example@gmail.com" class="input input-bordered w-full"/>
      <p *ngIf="contactInfoForm.controls['email'].invalid && contactInfoForm.controls['email'].touched" translate class="required">
        contact_info.required_field_email
      </p>
    </div>
    <div class="form-element">
      <app-phone-input
      [restaurantCountry]="country"
      (phoneNumberChange)="addPhoneNumberToForm($event)"
      >
    </app-phone-input>
    <p *ngIf="contactInfoForm.controls['phone'].invalid && contactInfoForm.controls['phone'].touched" translate class="required">
      contact_info.required_field_phone
    </p>
    </div>
    <div class="form-element">
      <label translate>contact_info.comment</label>
      <textarea formControlName="comment" type="text" [placeholder]="'contact_info.comment_placeholder' | translate" class="textarea textarea-bordered"></textarea>
      <p *ngIf="contactInfoForm.controls['comment'].invalid && contactInfoForm.controls['comment'].touched" translate class="required">
        contact_info.required_field
      </p>
    </div>

    <div class="flex justify-around">
      <button (click)="back()" type="button" class="btn btn-outline" [disabled]="isLoading">
        <span translate>common.back</span>
      </button>
      <button type="submit" class="btn btn-primary" [disabled]="isLoading">
        <app-loader *ngIf="isLoading"></app-loader>
        <span *ngIf="!isLoading" translate>common.reserve</span>
      </button>
    </div>
  </form>
</div>
