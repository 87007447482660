import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BookingRequest } from '@app/models/booking-request.model';
import { RestaurantInfoResponse } from '@app/models/restaurant-info-response.model';
import { AvailableTimeslotResponse } from '@app/models/get-available-timeslot-response.model';
import { CreateBookingResponse } from '@app/models/create-booking-response.model';
import { WidgetConfigurationsResponse } from '@app/models/widget-configurations.model';
import {environment} from "@env/environment";
import { OpeningHoursNameResponse } from '@app/models/get-opening-hours-name-by-date-response.model.ts';

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private readonly supabaseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getRestaurantInfo(tenantId: string, restaurantId: string, language: string = 'nb'): Observable<RestaurantInfoResponse> {
    return this.http.get<RestaurantInfoResponse>(`${this.supabaseUrl}/functions/v1/get-restaurants-and-areas?tenantId=${tenantId}&restaurantId=${restaurantId}&languageCode=${language}`);
  }

  getAvailableTimeslotsByDate(
    tenantId: string,
    restaurantId: string,
    areaId: number | null,
    paxCount: number,
    date: string
  ): Observable<AvailableTimeslotResponse> {

    const params = new HttpParams()
      .set('tenantId', tenantId)
      .set('restaurantId', restaurantId)
      .set('paxCount', paxCount.toString())
      .set('date', date);

    if (areaId !== null) {
      params.set('areaId', areaId.toString());
    }

    return this.http.get<AvailableTimeslotResponse>(`${this.supabaseUrl}/functions/v1/get-available-timeslots-by-date`, { params });
  }


  createBooking(bookingRequest: BookingRequest): Observable<CreateBookingResponse> {
    return this.http.post<any>(`${this.supabaseUrl}/functions/v1/create-booking`, bookingRequest);
  }

  getWidgetConfigurations(tenantId: string, restaurantId: string): Observable<WidgetConfigurationsResponse> {
    return this.http.get<any>(`${this.supabaseUrl}/functions/v1/get-widget-configurations?tenantId=${tenantId}&restaurantId=${restaurantId}`);
  }

  getOpeningHoursNameByDate(tenantId: string, restaurantId: string, areaId: number, date: string, language: string = 'nb'): Observable<OpeningHoursNameResponse> {
    return this.http.get<any>(`${this.supabaseUrl}/functions/v1/get-opening-hours-name-by-date?tenantId=${tenantId}&restaurantId=${restaurantId}&areaId=${areaId}&date=${date}&languageCode=${language}`);
  }

}
