import { Injectable } from '@angular/core';
import { RestaurantInfo } from '@app/models/restaurant-info.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private readonly _restaurantInfo = new Subject<RestaurantInfo>();
  readonly restaurantInfo$ = this._restaurantInfo.asObservable();

  constructor() { }


  setRestaurantInfo(restaurantInfo: RestaurantInfo) {
    this._restaurantInfo.next(restaurantInfo);
  }


}
