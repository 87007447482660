import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeatlyBookingWidgetComponent } from './seatly-booking-widget/seatly-booking-widget.component';
import { MissingAttributesErrorComponent } from './pages/missing-attributes-error/missing-attributes-error.component';

const routes: Routes = [
  { path: '', redirectTo: 'booking', pathMatch: 'full'},
  { path: 'booking', component: SeatlyBookingWidgetComponent, title: 'Book table'},
  { path: 'attribute-error', component: MissingAttributesErrorComponent, title: 'Attribute error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
