<div class="header">
  <h1 class="sm:text-xl md:text-2xl" translate>select_guests.title</h1>
</div>
<form (ngSubmit)="submit()" [formGroup]="numberOfGuestsForm">
  <div class="guest-buttons radioButtons">
    <div class="radioButton" *ngFor="let item of [].constructor(maxGuests); let i = index">
      <input
        [id]="'number-of-guests-option' + (i + 1)"
        formControlName="numberOfGuests"
        [value]="i + 1"
        type="radio"
        class="roundedPrimaryButton"
      />
      <label [for]="'number-of-guests-option' + (i + 1)">{{ i + 1 }}</label>
    </div>
    <div class="radioButton">
      <input id="to-many-guests" formControlName="numberOfGuests" [value]="maxGuests + 1" type="radio" class="roundedPrimaryButton" />
      <label for="to-many-guests">{{ maxGuests + 1 }}+</label>
    </div>
  </div>
  <div
    *ngIf="
      numberOfGuestsForm.controls['numberOfGuests'].hasError('max') &&
      numberOfGuestsForm.controls['numberOfGuests'].invalid &&
      numberOfGuestsForm.controls['numberOfGuests'].touched
    "
    class="to-many-guests"
    aria-live="assertive"
  >
    <p [translateParams]="{ maxGuests: maxGuests }" translate>select_guests.to_many_guest</p>
    <div *ngIf="contactPhone || contactEmail" class="contact-info">
      <h4 translate>common.contact_info</h4>
      <p *ngIf="contactEmail">
        <span translate>common.phone</span>: <a href="mailto:{{ contactPhone }}" target="_blank">{{ contactPhone }}</a>
      </p>
      <p *ngIf="contactPhone">
        <span translate>common.email</span>: <a href="tel:{{ contactEmail }}" target="_blank">{{ contactEmail }}</a>
      </p>
    </div>
  </div>
  <div
    *ngIf="
      numberOfGuestsForm.controls['numberOfGuests'].hasError('min') &&
      numberOfGuestsForm.controls['numberOfGuests'].invalid &&
      numberOfGuestsForm.controls['numberOfGuests'].touched
    "
  >
    <p translate class="error">select_guests.no_guests_selected</p>
  </div>

  <div class="flex">
    <span></span>
    <button type="submit" class="btn btn-primary" translate>common.next</button>
  </div>
</form>
