import { Component, OnInit, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {DateTime} from "luxon";
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactInfoForm } from '@app/pages/contact-info/contact-info.component';
import { SupabaseService } from '@app/services/supabase.service';
import { StateService } from '@app/services/state.service';
import { GeneralOpeningHours } from '@app/models/general-opening-hours.model';
import { BookingRequest } from '@app/models/booking-request.model';
import { WidgetConfigurations } from '@app/models/widget-configurations.model';
import { hexToRgb } from '@app/utils/hex-to-rgb';
import * as Sentry from "@sentry/angular";
import { Areas } from '@app/models/areas.model';

@UntilDestroy()
@Component({
  selector: 'app-seatly-booking-widget',
  templateUrl: './seatly-booking-widget.component.html',
  styleUrls: ['./seatly-booking-widget.component.css', '../../styles.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SeatlyBookingWidgetComponent implements OnInit, OnDestroy {
  // Web component inputs
  @Input() tenantId?: string;
  @Input() restaurantId?: string;
  isLoading: boolean = false;
  isLoadingWidgetConfigurations: boolean = false;

  // General settings for the booking widget
  maxNumberOfGuestsAcceptedOnline: number = 7; // fallback to 7 if not set in API
  totalSteps: number = 4;
  currentStep: number = 1;

  // Final form that will complete the booking.
  bookingForm = new FormGroup({
    numberOfGuests: new FormControl(0, [Validators.required, Validators.min(0), Validators.max(this.maxNumberOfGuestsAcceptedOnline)]),
    date: new FormControl<DateTime>(DateTime.now(), [Validators.required]),
    time: new FormControl(0, [Validators.required]),
    contactPerson: new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      comment: new FormControl(''),
    }),
  });
  selectedDate: DateTime | null = null;
  bookingRequest?: BookingRequest;

  // API data needed to generate the form for the booking widget.
  restaurantName?: string;
  address?: string;
  city?: string;
  country?: string;
  contactEmail?: string;
  contactPhone?: string;
  generalOpeningHours: GeneralOpeningHours[] = [];
  areaId?: number;
  areas?: Areas[];
  missingRequiredAttributes: boolean = false;

  // Subscriptions
  restaurantInfoSubscription?: Subscription;
  isPreviewMode: boolean = false;
  private previewConfiguration: WidgetConfigurations = {
    primaryColor: '#efc805',
    primaryColorContrast: '#d90c0c',
    hasFrame: true,
    font: 'Arial',
    fontColor: '#02e802',
    backgroundColor: '#e8e8e8',
    restaurantId: '0',
    id: '0',
    classes: '',
    borderRadius: 25,
  };
  classes: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private supabaseService: SupabaseService,
    private stateService: StateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.getParams();
    this.getWidgetConfigurations();
    this.getRestaurantData();
    this.i18nSetup();
    this.handleRouteChanges();
  }

  private handleRouteChanges() {
    this.route.queryParams.subscribe(params => {
      let step = 1;
      if (this.currentStep === 5) {
        const { guestCount, date, timeOfDay, ...defaultParams } = params;
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: defaultParams,
          replaceUrl: true
        });
      } else {
        if (params['guestCount']) {
          this.bookingForm.get('numberOfGuests')?.setValue(Number(params['guestCount']));
          step = 2;
        };
        if (params['date']) {
          this.selectedDate = DateTime.fromISO(params['date']);
          this.bookingForm.get('date')?.setValue(this.selectedDate);
          step = 3;
        };
        if (params['timeOfDay']) {
          this.bookingForm.get('time')?.setValue(params['timeOfDay']);
          step = 4;
        };
      };
      this.currentStep = step;
    });
  }
  private updateRouteParams() {
    let queryParams = { ...this.activatedRoute.snapshot.queryParams };
    if (this.currentStep === 1) {
      const { guestCount, date, timeOfDay, ...rest } = queryParams;
      queryParams = rest;
    }
    if (this.currentStep === 2) {
      const { date, timeOfDay, ...rest } = queryParams;
      queryParams = rest;
      const numberOfGuests = this.bookingForm.get('numberOfGuests')?.value;
      if (numberOfGuests !== null && numberOfGuests !== undefined) {
        queryParams['guestCount'] = numberOfGuests;
      }
    }
    if (this.currentStep === 3) {
      const { timeOfDay, ...rest } = queryParams;
      queryParams = rest;
      const date = this.bookingForm.get('date')?.value?.toISODate();
      if (date) {
        queryParams['date'] = date;
      }
    }
    if (this.currentStep === 4) {
      const timeOfDay = this.bookingForm.get('time')?.value;
      if (timeOfDay !== null && timeOfDay !== undefined && timeOfDay !== 0) {
        queryParams['timeOfDay'] = timeOfDay;
      }
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge"
    });
  }
  
  private getParams() {
    // If the tenantID and restaurantID is not set trough the web-component,
    // try to get it from the URL.
    if (!this.tenantId && !this.restaurantId) {
      this.route.queryParams.subscribe(params => {
        this.tenantId = params['tenantId'];
        this.restaurantId = params['restaurantId'];
        this.isPreviewMode = params['isPreviewMode'];
        if (this.isPreviewMode) {
          this.previewConfiguration = {
            primaryColor: decodeURIComponent(params['primaryColor']),
            primaryColorContrast: decodeURIComponent(params['primaryColorContrast']),
            hasFrame: params['hasFrame'] === 'true',
            font: params['font'],
            restaurantId: params['restaurantId'],
            classes: decodeURIComponent(params['classes']),
            fontColor: decodeURIComponent(params['fontColor']),
            borderRadius: +decodeURIComponent(params['borderRadius']),
            backgroundColor: decodeURIComponent(params['backgroundColor']),
            id: '',
          };
        }
        console.log('Params:', this.tenantId, this.restaurantId, this.isPreviewMode, this.previewConfiguration)

        Sentry.setUser({
          'Tenant Id': this.tenantId,
          'Restaurant Id': this.restaurantId,
          'Preview mode': this.isPreviewMode,
        })
      });
    }
  }

  private getWidgetConfigurations() {
    if (this.tenantId) {
      this.isLoadingWidgetConfigurations = true;

      if (this.isPreviewMode) {
        this.setStyle(this.previewConfiguration);
        this.isLoadingWidgetConfigurations = false;
      } else {
        this.supabaseService.getWidgetConfigurations(this.tenantId!, this.restaurantId!).subscribe({
          next: response => {
            if(response.error) throw response.error;
            const data = response.data;

            if(data) {
              this.setStyle(data);
            }
          },
          error: error => {
            console.error(error);
          },
          complete: () => {
            this.isLoadingWidgetConfigurations = false;
          }
        });
      }
    }
  }

  private setStyle(widgetConfigurations: WidgetConfigurations){
    if(widgetConfigurations.primaryColor){
      document.documentElement.style.setProperty('--primary-color', widgetConfigurations.primaryColor);
      // document.documentElement.style.setProperty('--p', widgetConfigurations.primaryColor);
      const primaryColorLight = hexToRgb(widgetConfigurations.primaryColor);
      // create a lighter color if primar color is set with opacity
      if(primaryColorLight){
        document.documentElement.style.setProperty('--primary-color-lighter', `rgba(${primaryColorLight.r}, ${primaryColorLight.g}, ${primaryColorLight.b}, 0.5)`);
      }
    }
    if(widgetConfigurations.primaryColorContrast){
      document.documentElement.style.setProperty('--primary-color-contrast', widgetConfigurations.primaryColorContrast);
    }
    if(widgetConfigurations.hasFrame){
      this.classes += ' shadow-xl';
    }

    if(widgetConfigurations.font){
      document.documentElement.style.fontFamily = widgetConfigurations.font;
      document.body.style.fontFamily = widgetConfigurations.font;
    }

    if(widgetConfigurations.classes){
      this.classes += ' ' + widgetConfigurations.classes;
    }

    if(widgetConfigurations.fontColor){
      document.body.style.color = widgetConfigurations.fontColor;
    }

    if(widgetConfigurations.borderRadius){
      document.documentElement.style.setProperty('--border-radius', widgetConfigurations.borderRadius + 'px');
    }

    if(widgetConfigurations.backgroundColor){
      document.body.style.backgroundColor = widgetConfigurations.backgroundColor;
    }

  }

  private getRestaurantData() {
    this.isLoading = true;
    // Still no tenantID or restaurantId. Throw error.
    if (!this.tenantId || !this.restaurantId) {
      console.error(
        'No tenantID or restaurantID provided. Please provide these as attributes to the web-component or as query parameters in the URL.',
      );
      this.router.navigate(['/attribute-error']);
      return;
    }

    // Get restaurant info from API
    this.restaurantInfoSubscription = this.supabaseService.getRestaurantInfo(this.tenantId, this.restaurantId).subscribe(
      restaurantInfo => {
        if (restaurantInfo.status === 200) {
          if (restaurantInfo.data.length === 0) {
            return;
          }
          // Can prob to all this into one model. Not sure why i mapped it into
          // one and one value. But can fix one day....
          const data = restaurantInfo.data[0];
          this.stateService.setRestaurantInfo(data);
          this.restaurantName = data.name;
          this.address = data.address;
          this.city = data.city;
          this.country = data.country;
          this.contactEmail = data.contactEmail;
          this.contactPhone = data.contactPhone;
          this.generalOpeningHours = data.general_opening_hours;
          // update max bookable guests with new number if set in database, or use 7 as defautl fallback
          if(data.maxNumberOfGuestsAcceptedOnline){
            this.maxNumberOfGuestsAcceptedOnline = data.maxNumberOfGuestsAcceptedOnline;
            // update validators for number of guests with new number
            this.bookingForm.get('numberOfGuests')?.setValidators([Validators.required, Validators.min(0), Validators.max(this.maxNumberOfGuestsAcceptedOnline)]);
          }
          if (data.areas && data.areas.length > 0) { // sjekker om areas kommer ut for hvis det er en ny restaurant så er det ikke sikker denne er laget enda
            this.areaId = data.areas[0].id;
            this.areas = data.areas;
          }
          this.titleService.setTitle(this.translateService.instant('common.title', { restaurantName: this.restaurantName }));
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  private i18nSetup() {
    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd).pipe(
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data),
      untilDestroyed(this),
    );
  }

  selectGuests(numberOfGuestsSelecetd: number) {
    console.info('Number of guests selected:', numberOfGuestsSelecetd);
    this.bookingForm.get('numberOfGuests')?.setValue(numberOfGuestsSelecetd);
    this.currentStep = 2;
    this.updateRouteParams();
  }

  onSelectDate(date: DateTime) {
    console.info('Date selected:', date);
    this.selectedDate = date;
    this.bookingForm.get('date')?.setValue(date);
    this.currentStep = 3;
    this.updateRouteParams();
  }

  onSelectTime(time: number) {
    console.info('Time selected:', time);
    this.bookingForm.get('time')?.setValue(time);
    this.currentStep = 4;
    this.updateRouteParams();
  }

  onSubmitContactInfo(contactInfo: ContactInfoForm) {

    this.isLoading = true;
    this.bookingForm.get('contactPerson')?.setValue(contactInfo);
    if (this.bookingForm.valid && this.tenantId && this.restaurantId && this.areaId && this.bookingForm.value.date) {
      const bookingRequest: BookingRequest = {
        tenantId: this.tenantId,
        restaurantId: Number.parseInt(this.restaurantId),
        areaId: this.areaId,
        paxCount: this.bookingForm.value.numberOfGuests ?? 0,
        date: this.bookingForm.value.date.toFormat('yyyy-MM-dd'),
        timeslot: this.bookingForm.value.time!,
        source: 'Online Registration',
        customerNotes: this.bookingForm.value.contactPerson?.comment ?? '',
        customer: {
          firstname: this.bookingForm.value.contactPerson?.firstname ?? '',
          lastname: this.bookingForm.value.contactPerson?.lastname ?? '',
          phoneNumber: this.bookingForm.value.contactPerson?.phone ?? '',
          email: this.bookingForm.value.contactPerson?.email ?? '',
          gdprConsent: DateTime.now().plus({year: 2}).toFormat('yyyy-MM-dd'),
          desiredCommunicationLanguage: navigator.language.substring(0, 2),
        },
      };
      this.supabaseService.createBooking(bookingRequest).subscribe(
        response => {
          if (response.bookingStatus === 'confirmed') {
            this.bookingRequest = bookingRequest;
            this.currentStep = 5;
            this.isLoading = false;
          } else {
            this.currentStep = 500; // error code
            this.isLoading = false;
          }
        },
        (e) => {
          this.isLoading = false;
          console.error('Error', e);
        },
      );
    }
  }

  onBack() {
    this.currentStep -= 1;
    this.updateRouteParams();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
    this.restaurantInfoSubscription?.unsubscribe();
  }
}
