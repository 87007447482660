import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CountryFlagService {
  constructor(private http: HttpClient) {}
  getCountryFlags(code: string): Observable<any> {
    return this.http.get<any>(`https://flagcdn.com/32x24/${code}.png`, { responseType: 'blob' as 'json' })
  }
}
