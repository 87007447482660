import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Timeslot as APITimeslot } from '@app/models/timeslot.model';
import { SupabaseService } from '@app/services/supabase.service';
import { convertMinutesSinceMidnightToHumanReadableTime } from '@app/utils/time';
import { forkJoin, map, of, Subscription, switchMap, tap } from 'rxjs';
import { DateTime } from "luxon";
import { Areas } from '@app/models/areas.model';


//Use the interface timeslot but extend it with one more property named readableTime
interface Timeslot extends APITimeslot {
  readableTime: string;
}

interface NamedTimeSlots {
  area: Areas;
  timeslots: {
    label?: string;
    slots: Timeslot[];
  }[]
}

@Component({
  selector: 'app-select-time',
  templateUrl: './select-time.component.html',
  styleUrls: ['./select-time.component.css'],
})
export class SelectTimeComponent implements OnInit, OnDestroy {
  @Input() tenantId?: string;
  @Input() restaurantId?: string;
  @Input() areaId?: number;
  @Input() numberOfGuests?: number;
  @Input() selectedDate?: DateTime;
  @Input() selectedTime?: number;

  @Input() contactPhone?: string;
  @Input() contactEmail?: string;

  @Input() areas?: Areas[];

  // Should be fetched from API
  isLoading = false;
  groupedTimeslots: NamedTimeSlots[] = [];
  selectedArea?: NamedTimeSlots; // Can be incorporated in groupedTimeslot as isSelected.
  seletedTimeForm: FormGroup = new FormGroup({
    time: new FormControl<number | undefined>(undefined, [Validators.required]),
    area: new FormControl<number | undefined>(undefined, [Validators.required]),
  });

  @Output() onBack = new EventEmitter<boolean>();
  @Output() onSelectTime = new EventEmitter<number>();

  timeslotSubscription?: Subscription;

  constructor(private supabaseService: SupabaseService) { }

  ngOnInit(): void {

    this.isLoading = true;

    if (this.tenantId && this.restaurantId && this.numberOfGuests && this.selectedDate) {

      if (this.selectedTime) {
        this.seletedTimeForm.get('time')?.setValue(this.selectedTime);
      }

      const stringDate = this.selectedDate.toFormat('yyyy-MM-dd');

      if (!this.areas || this.areas.length === 0) {
        console.error('No areas in restaurant.');
        this.isLoading = false;
        return;
      }

      const groupedTimeslots: NamedTimeSlots[] = this.areas
        .filter(a => a.availableOnline)
        .map(area => ({
          area,
          timeslots: []
        }));

      if (groupedTimeslots.length === 0) {
        console.warn('No available areas found');
        this.isLoading = false;
        return;
      }

      const areaRequests = groupedTimeslots.map(group =>
        forkJoin({
          namedOpeningHours: this.supabaseService.getOpeningHoursNameByDate(
            this.tenantId!, this.restaurantId!, group.area.id, stringDate
          ),
          timeslots: this.supabaseService.getAvailableTimeslotsByDate(
            this.tenantId!, this.restaurantId!, group.area.id, this.numberOfGuests!, stringDate
          )
        }).pipe(
          tap(({ namedOpeningHours, timeslots }) => {

            if (namedOpeningHours.error) {
              throw new Error('Error fetching opening hours.');
            }

            if (timeslots.error) {
              throw new Error('Error fetching timeslots.');
            }

            const formattedTimeslots = timeslots.data.map((timeslot: APITimeslot) => {
              const readableTime = convertMinutesSinceMidnightToHumanReadableTime(timeslot.minutesfrommidnight);
              return { ...timeslot, readableTime };
            });

            if (formattedTimeslots.length === 0) {
              return;
            }

            if (namedOpeningHours.data.length === 0) {
              group.timeslots.push({
                slots: formattedTimeslots
              });
              return;
            }

            namedOpeningHours.data.forEach(item => {
              const slots = this.filterTimeslotsByGroup(formattedTimeslots, item.opens, item.closes);
              group.timeslots.push({
                label: item.text,
                slots: slots
              });
            });
          })
        )
      );

      forkJoin(areaRequests).subscribe({
        next: () => {
          const defaultArea = { name: 'Any', id: 0, availableOnline: true };
          const defaultTimeslot = {
            slots: groupedTimeslots
              .flatMap(group => group.timeslots.flatMap(g => g.slots))
              .sort((a, b) => a.minutesfrommidnight - b.minutesfrommidnight)
              .filter((slot, index, self) =>
                index === self.findIndex(s => s.minutesfrommidnight === slot.minutesfrommidnight)
              )
          };
          const defaultGroup = {
            area: defaultArea,
            timeslots: [defaultTimeslot]
          };

          groupedTimeslots.unshift(defaultGroup);

          this.groupedTimeslots = groupedTimeslots.map(area => {
            area.timeslots.sort((a, b) => {
              const earliestA = Math.min(...a.slots.map(slot => slot.minutesfrommidnight));
              const earliestB = Math.min(...b.slots.map(slot => slot.minutesfrommidnight));
              return earliestA - earliestB;
            });

            return area;
          });

          this.selectedArea = this.groupedTimeslots.find(item => item.area.id === 0);
        },
        error: error => {
          console.error('Error during data loading:', error);
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          console.log('All data loaded successfully.');
        }
      });

    } else {
      console.error('Required attributes are missing.');
    }
  }


  back() {
    this.onBack.emit(true);
  }

  ngOnDestroy(): void {
    this.timeslotSubscription?.unsubscribe();
  }

  submit() {
    this.seletedTimeForm.markAllAsTouched();
    const selectedTime = this.seletedTimeForm.get('time')?.value;
    if (selectedTime) {
      this.onSelectTime.emit(this.seletedTimeForm.get('time')?.value);
    }
  }


  onAreaChange(areaId: number): void {

    this.seletedTimeForm.get('area')?.setValue(areaId); // Kun nødvendig dersom vi skal bruke areaId i submit form..

    const selectedGroup = this.groupedTimeslots.find(
      value => value.area?.id === areaId
    );

    if (!selectedGroup) {
      console.error('Selected area is not in grouped timeslots.');
      return;
    }

    this.selectedArea = selectedGroup;
  }

  filterTimeslotsByGroup(timeslots: Timeslot[], opens: number, closes: number): Timeslot[] {
    return timeslots.filter(timeslot =>
      timeslot.minutesfrommidnight >= opens &&
      timeslot.minutesfrommidnight <= closes
    );
  }

}
