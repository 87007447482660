import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent {
    @Input() currentStep: number = 1;
    @Input() selectedGuests?: number;
    @Input() selectedDate?: Date;
    @Input() selectedTime?: string;

}
