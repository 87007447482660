import { env } from './.env';

export const environment = {
  production: true,
  staging: true,
  version: env['npm_package_version'] + '-staging',
  defaultLanguage: 'en-US',
  supportedLanguages: ['de-DE', 'en-US'],
  baseUrl: 'https://kfficefinzpnlvyshfgs.supabase.co'
};
