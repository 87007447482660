import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneralOpeningHours } from '@app/models/general-opening-hours.model';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from "luxon";
import { DayOfWeek, getWeekStartByLocale } from 'weekstart';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.css'],
})
export class SelectDateComponent implements OnInit {
  @Input() numberOfGuests: number = 0;
  @Input() selectedDate?: DateTime;
  // From API
  @Input() generalOpeningHours: GeneralOpeningHours[] = [];

  selectedDateForm: FormGroup;
  firstDayOfWeek!: DayOfWeek;
  startDate!: NgbDateStruct;
  endDate!: NgbDateStruct;
  
  @Output() onBack = new EventEmitter<boolean>();
  @Output() onSelectDate = new EventEmitter<DateTime>();

  constructor(private calendar: NgbCalendar) {
    const today = calendar.getToday();
    this.startDate = today;
    this.endDate = { ...today, year: today.year + 1 };
    this.selectedDateForm = new FormGroup({
      date: new FormControl<NgbDateStruct | undefined>(undefined, [Validators.required]),
    });
    this.firstDayOfWeek = getWeekStartByLocale(Intl.Locale.name);
  }

  ngOnInit(): void {
    if (this.selectedDate) {
      const formattedDate = new NgbDate(this.selectedDate.year, this.selectedDate.month, this.selectedDate.day);
      this.selectedDateForm.get('date')?.setValue(formattedDate);
    }
  }

  getDisabledDates = (ngbDate: NgbDate) => {
    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    return !this.isRestaurantOpen(date);
  }

  isRestaurantOpen(date: Date): boolean {
    const weekday = DateTime.fromJSDate(date).weekday;
    let allOpeningHoursThisWeekday = this.generalOpeningHours.filter(openingHours => openingHours.weekday === weekday);

    if (allOpeningHoursThisWeekday.length === 0) {
      return false;
    } else if (allOpeningHoursThisWeekday.length > 0) {
      return true;
    }

    return false;
  }

  back() {
    this.onBack.emit(true);
  }

  submit() {
    this.selectedDateForm.markAllAsTouched();

    const date = this.selectedDateForm.get('date')?.value;
    if (date) {
      this.onSelectDate.emit(DateTime.local(date?.year, date?.month, date?.day));
    }
  }
}
