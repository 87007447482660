<div class="select-date-wrapper" *ngIf="!isLoading">

  <div class="summary-wrapper">
    <h2 class="stepper-title" translate>select_date.title</h2>
    <span [translateParams]="{ guests: numberOfGuests }" translate>stepper.date_summary</span>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="selectedDateForm">
    <div class="calendar">
      <ngb-datepicker
        [firstDayOfWeek]="firstDayOfWeek"
        [minDate]="startDate"
        [maxDate]="endDate"
        [markDisabled]="getDisabledDates"
      class="datepicker" #dp formControlName="date"></ngb-datepicker>
    </div>


    <div *ngIf="selectedDateForm.controls['date'].invalid && selectedDateForm.controls['date'].touched">
      <p translate class="error">select_date.no_date_selected</p>
    </div>
    <div class="flex flex-row justify-between">
      <button (click)="back()" type="button" class="btn btn-outline">
        <span translate>common.back</span>
      </button>
      <button type="submit" class="btn btn-primary">
        <span translate>common.next</span>
      </button>
    </div>
  </form>
</div>
