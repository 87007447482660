<div class="max-w-md flex flex-col items-center">

  <div class="flex flex-col items-center w-full mt-7 min-sm:mb-7 text-center">
    <h1 class="sm:text-xl md:text-2xl">Book a table at {{restaurantName}}</h1>
    <p class="max-[320px]:text-xs" *ngIf="address && city && country">
      <a [href]="'https://www.google.com/maps/search/?api=1&query=' + restaurantName + ',' + city + ',' + country" target="_blank">
        {{address}}, {{city}}
      </a>
    </p>
  </div>
  <div *ngIf="!isLoadingWidgetConfigurations" class="card w-auto bg-base-100 p-2 {{classes}}" style="{{isPreviewMode ? 'margin-top: 20px!important' : ''}}">
    <!-- stepper navigator -->
    <app-stepper [currentStep]="currentStep"></app-stepper>

    
    
    <!-- stepper content -->
    <div class="p-4">
      <app-select-guests
        *ngIf="currentStep === 1"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [contactEmail]="contactEmail"
        [contactPhone]="contactPhone"
        [maxGuests]="maxNumberOfGuestsAcceptedOnline"
        [restaurantName]="restaurantName"
        [isLoading]="isLoading"
        (selectGuests)="selectGuests($event)"
      ></app-select-guests>
      <app-select-date
        *ngIf="currentStep === 2"
        (onSelectDate)="onSelectDate($event)"
        [selectedDate]="bookingForm.value.date ?? undefined"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [generalOpeningHours]="generalOpeningHours"
        (onBack)="onBack()"
      >
      </app-select-date>
      <app-select-time
        *ngIf="currentStep === 3"
        (onSelectTime)="onSelectTime($event)"
        [selectedTime]="bookingForm.value.time ?? undefined"
        [selectedDate]="bookingForm.value.date ?? undefined"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [contactEmail]="contactEmail"
        [contactPhone]="contactPhone"
        [areaId]="areaId"
        [restaurantId]="restaurantId"
        [tenantId]="tenantId"
        [areas]="areas"
        (onBack)="onBack()"
      >
        >
      </app-select-time>
      <app-contact-info
        *ngIf="currentStep === 4"
        [isLoading]="isLoading"
        (onSubmitContactInfo)="onSubmitContactInfo($event)"
        [selectedDate]="bookingForm.value.date ?? undefined"
        [selectedTime]="bookingForm.value.time ?? undefined"
        [numberOfGuests]="bookingForm.value.numberOfGuests ?? 0"
        [country]="country"
        (onBack)="onBack()"
      >
      </app-contact-info>
      <app-confirmation
        *ngIf="currentStep === 5 && !isLoading"
        [bookingRequest]="bookingRequest"
        [restaurantName]="restaurantName"
        [contactEmail]="contactEmail"
        [contactPhone]="contactPhone"
      ></app-confirmation>

      

    </div>
  </div>
  <div class="mt-7 text-center w-75" style="color: #adadad">
    <p>This restaurant reservation and booking system is delivered by <a href="https://resmium.com">Resmium</a></p>
  </div>




</div>
