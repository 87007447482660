import { Component } from '@angular/core';

/**
 *  Imporant!
 *
 * The AppComponent is not used by the web-components.
 * It is only used by the Angular application when debugging and
 * running locally.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {}
