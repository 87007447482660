<div class="stepper">
  <button class="step" [ngClass]="{ currentStep: currentStep >= 1 }">
    <span>1.&nbsp;</span>
    <span translate>common.guests</span>
  </button>
  <button class="step" [disabled]="currentStep < 2" [ngClass]="{ currentStep: currentStep >= 2 }">
    <span>2.&nbsp;</span>
    <span translate>common.date</span>
  </button>
  <button class="step" [disabled]="currentStep < 3" [ngClass]="{ currentStep: currentStep >= 3 }">
    <span>3.&nbsp;</span>
    <span translate>common.time</span>
  </button>
</div>
