import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryFlagService } from '@app/services/countryFlag.service';
import { getCountryDataList } from 'countries-list'

interface Country {
  label: string;
  code: string;
  callingCode: string;
  flag?: string;
}

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})

export class PhoneInputComponent {

  @Input() restaurantCountry?: string;
  @Output() phoneNumberChange = new EventEmitter<string>();

  countries: Country[] = [];
  isVisible: boolean = false;

  phoneNumberForm: FormGroup = new FormGroup({
    country: new FormControl<Country | undefined>(undefined, [Validators.required]),
    phoneNumber: new FormControl<string | undefined>(undefined, [Validators.required]),
  });

  constructor(private countryFlagService: CountryFlagService) {}

  ngOnInit(): void {

    this.countries = getCountryDataList().map(item => ({
      label: item.name,
      code: item.iso2,
      callingCode: item.phone[0] ? "+" + item.phone[0] : "",
    })).sort((a, b) => a.label.localeCompare(b.label));

    const initialCountry = this.countries.find(country => country.label === this.restaurantCountry) ?? this.countries.find(country => country.label === "Norway");

    if (!initialCountry) return;

    this.countryFlagService.getCountryFlags(initialCountry.code.toLowerCase()).subscribe(
      (countryFlag) => {
        initialCountry.flag = URL.createObjectURL(countryFlag);
        this.phoneNumberForm.patchValue({ country: initialCountry });
        this.phoneNumberForm.valueChanges.subscribe(() => {
          this.emitPhoneNumber();
        });
      },
      (error) => {
        console.error('Error fetching country flag', error);
      }
    );
  }

  selectCountry(country: Country) {
    this.countryFlagService.getCountryFlags(country.code.toLowerCase()).subscribe(
      (countryFlag) => {
        country.flag = URL.createObjectURL(countryFlag);
        this.phoneNumberForm.patchValue({ country });
        this.toggleDropdown();
      },
      (error) => {
        console.error('Error fetching country flag', error);
      }
    );
  }

  toggleDropdown() {
    this.isVisible = !this.isVisible
  }

  emitPhoneNumber() {
      const formValue = this.phoneNumberForm.value;
      if (formValue.country.callingCode && formValue.phoneNumber) {
        const fullPhoneNumber = `${formValue.country.callingCode}${formValue.phoneNumber}`
        this.phoneNumberChange.emit(fullPhoneNumber);
      }
  }
}