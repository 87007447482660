<div class="select-date-wrapper">

  <div class="summary-wrapper">
    <h2 class="stepper-title" translate>select_time.title</h2>
    <span [translateParams]="{ guests: numberOfGuests }" translate>stepper.date_summary&nbsp;</span>
    <span translate>• {{ selectedDate?.toFormat('d MMMM yyyy')}} </span>
  </div>

  <form (ngSubmit)="submit()" [formGroup]="seletedTimeForm">
    <div class="times">
      <div class="radioButtons">
        <ng-container *ngIf="isLoading">
          <div *ngFor="let item of [].constructor(18); let i = index">
            <div class="radioButton">
              <app-shimmer [styling]="{ height: '54px', width: '54px', 'border-radius': '50%' }"></app-shimmer>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!isLoading">

        <!-- Display grouped timeslots -->
        <div class="flex flex-col items-start gap-6" *ngIf="servingAreaChoice">
          
            <div class="flex flex-col gap-2" *ngIf="groupedTimeslots.length > 0">
              <p translate> select_time.available_areas </p>
              <div class="flex gap-2">
                <button
                  class="btn btn-outline btn-sm" 
                  type="button" 
                  *ngFor="let group of groupedTimeslots" 
                  [ngClass]="{'btn-active': group.area.id === selectedArea?.area?.id}" 
                  (click)="onAreaChange(group.area.id)"
                >
                  {{ group.area.name }}
                </button>
              </div>
            </div>

          <div class="flex flex-col gap-2" *ngFor="let group of selectedArea?.timeslots">
            <p> {{ group.label }} </p>
            <div class="radioButtons">
              <div *ngFor="let timeSlot of group.slots">
                <input
                  [id]="timeSlot.minutesfrommidnight"
                  formControlName="time"
                  [value]="timeSlot.minutesfrommidnight"
                  type="radio"
                  class="roundedPrimaryButton"
                />
                <label [for]="timeSlot.minutesfrommidnight">{{ timeSlot.readableTime }}</label>
              </div>
            </div>
          </div>
        </div>

        <!-- Display all timeslots -->
        <div class="radioButtons" *ngIf="!servingAreaChoice">
          <div *ngFor="let timeSlot of allTimeslots">
            <input
              [id]="timeSlot.minutesfrommidnight"
              formControlName="time"
              [value]="timeSlot.minutesfrommidnight"
              type="radio"
              class="roundedPrimaryButton"
            />
            <label [for]="timeSlot.minutesfrommidnight">{{ timeSlot.readableTime }}</label>
          </div>
        </div>

        </ng-container>
      </div>
    </div>
    <div *ngIf="(servingAreaChoice && !selectedArea || selectedArea?.timeslots?.length === 0) || (!servingAreaChoice && allTimeslots.length === 0) && !isLoading" class="no-times-available">
      <h3 class="error" translate>select_time.no_times_available</h3>
      <p translate>select_time.contact_restaurant</p>
      <div *ngIf="contactPhone || contactEmail" class="contact-info">
        <h4 translate>select_time.contact_info</h4>
        <p *ngIf="contactPhone"><span translate>select_time.phone</span>:&nbsp;<a [href]="'tel:' + contactPhone">{{contactPhone}}</a></p>
        <p *ngIf="contactEmail"><span translate>select_time.email</span>:&nbsp;<a [href]="'mailto:' + contactEmail">{{contactEmail}}</a></p>
      </div>
    </div>
    <div *ngIf="seletedTimeForm.controls['time'].invalid && seletedTimeForm.controls['time'].touched">
      <p translate class="error">select_date.no_date_selected</p>
    </div>
    <div class="flex justify-around">
      <button (click)="back()" type="button" class="btn btn-outline">
        <span translate>common.back</span>
      </button>
      <button type="submit" class="btn btn-primary">
        <span translate>common.next</span>
      </button>
    </div>
  </form>
</div>
